import React from 'react';

import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle: 'Charlotte, NC',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/Mys-3gF88PE',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      North Carolina, a state that once sent more Confederate soldiers into the
      Civil War than any other Southern state, has moved to embrace its
      diversity, a step which Charlotte’s population reflects. In 1980,
      Charlotte was a majority-white city. But by 2010, people of color had gone
      from one-third to over half of the population. The economic vitality of
      this city is threatened by racial inequality. Black unemployment is more
      than 2.5 times the rate for white workers. There are also wide gaps in
      earnings by race and gender. White workers earn $27 per hour compared to
      just $16 for blacks and $12 for Latino workers. Charlotte’s regional
      economy would be $25 billion stronger with no racial income gaps. The
      skills gap will continue to grow if Charlotte does not take steps to
      increase educational attainment among black and Latino residents.
      Approximately 42 percent of jobs in Charlotte require some college
      education. While 69 percent of white people meet this requirement, only 30
      percent of black people and 42 percent of Latino people are educated
      enough to compete.
    </p>
    <p>
      In Charlotte, we conducted interviews with individuals with the help of
      Charlotte’s Community Building Initiative (CBI), a nonprofit established
      in 1997 by government and civic leaders to achieve racial and ethnic
      inclusion and equity. Leaders from CBI and City Hall helped to organize a
      small group discussion at City Hall. Rev. Jacotran Potts also took us on a
      tour of several communities. We conducted three focus groups composed of
      college-educated black men and non-college-educated black and white women.
      Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
